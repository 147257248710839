// Path: sgp\src\assets\scss\components\_breadcrumb.scss
// breadcrumb.scss
//


// breadcrumb without background
.breadcrumb-no-bg {
    background-color: transparent;
}

// Breadcrumb item arrow
.breadcrumb-item {
    + .breadcrumb-item {
        align-items: center !important;

        &::before {
            // background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='1rem' height='1rem' stroke='currentColor' strokeWidth='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
            vertical-align: middle;
            width: 1rem;
            height: 1rem;
        }
    }
}
