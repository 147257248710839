// Path: sgp\src\assets\scss\components\_list.scss
// list.scss
//


.list-with-separator {
    li {
        &::after {
            content: "-";
            color: $gray-400;
            font-weight: 900;
            margin: 0 10px;
        }
    }

    li:last-child {
        &::after {
            content: '';
        }
    }
}