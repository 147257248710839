// Path: sgp\src\assets\scss\components\_print.scss
// print.scss
//


// Used invoice page
@media print {
    .left-side-menu,
    .right-bar,
    .page-title-box,
    .navbar-custom,
    .footer {
        display: none;
    }
    .card-body,
    .content-page,
    .right-bar,
    .content,
    body {
        padding: 0;
        margin: 0;
    }
}