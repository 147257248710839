// Path: sgp/src/assets/scss/_mixins.scss

@mixin button-outline-variant($color) {
    color: $color;
    border-color: $color;
    background-color: transparent;
  
    &:hover,
    &:focus,
    &:active,
    &.active,
    .show > &.dropdown-toggle {
      color: $body-color;
      background-color: $color;
      border-color: $color;
    }
  
    &:disabled,
    &.disabled {
      color: $color;
      background-color: transparent;
    }
  }
  