// Path: sgp\src\assets\scss\components\_helper.scss
// helper.scss
//


// Minimum width

.width-xs {
  min-width: 80px;
}

.width-sm {
  min-width: 95px;
}

.width-md {
  min-width: 110px;
}

.width-lg {
  min-width: 140px;
}

.width-xl {
  min-width: 160px;
}

// Text specify lines (Only chrome browser support)

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.sp-line-1 {
  -webkit-line-clamp: 1;
}

.sp-line-2 {
  -webkit-line-clamp: 2;
}

.sp-line-3 {
  -webkit-line-clamp: 3;
}

.sp-line-4 {
  -webkit-line-clamp: 4;
}

// pull in
.pull-in {
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

// border-radius
.rounded-sm {
  border-radius: $border-radius-sm;
}
.rounded-lg {
  border-radius: $border-radius-lg;
}

.cursor-pointer {
  cursor: pointer;
}
