/* Path: sgp/src/assets/css/dashboard.css */

.container {
    margin-top: 20px;
}

.card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.card-text {
    color: #6c757d;
    font-size: 1rem;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}
