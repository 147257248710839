/* Primary colors */
:root {
    --primary-color: #0056b3;
    --secondary-color: #000000;
    --background-color: #f8f9fa;
    --text-color: #333;
    --hover-background-color: #e2e6ea; /* Cor de fundo no hover para os links */
}

/* Body and text */
body {
    font-family: 'Open Sans', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
}

.texto {
    color: white
}

/* Navbar centralizado */
.navbar {
    background-color: white !important;
    border-bottom: 1px solid #e5e5e5;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Ajuste do Container da Navbar */
.container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-nav {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.navbar-nav .nav-item {
    margin: 0 10px;
}

.navbar-nav .nav-link {
    color: #3d4045 !important;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link {
    display: flex;
    align-items: center;
    color: #3d4045 !important;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
    color: var(--primary-color) !important;
    background-color: var(--hover-background-color) !important;
}

.navbar-nav .nav-link:hover {
    color: var(--primary-color) !important;
    background-color: var(--hover-background-color) !important;
}

.navbar-toggler {
    margin-left: auto;
}

.navbar-nav.ms-auto {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-item {
    margin-left: 15px; /* Espaçamento entre os itens */
}

/* Buttons */
.btn-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: #fff !important;
}

.btn-primary:hover {
    background-color: #004494 !important;
    border-color: #004494 !important;
    color: #fff !important;
}

.btn-success {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    color: #fff !important;
}

.btn-success:hover {
    background-color: #218838 !important;
    border-color: #218838 !important;
    color: #fff !important;
}

.btn-danger {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: #fff !important;
}

.btn-danger:hover {
    background-color: #c82333 !important;
    border-color: #c82333 !important;
    color: #fff !important;
}

/* Estilo de Tabela Compartilhada */
.table-shared {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
}

.table-shared th, .table-shared td {
    padding: 12px;
    vertical-align: middle;
    text-align: left;
    border-top: 1px solid #ddd;
}

.table-shared th {
    background-color: #f8f9fa;
    font-weight: bold;
    color: #333;
}

.table-shared tbody tr:hover {
    background-color: #f1f1f1;
}

.table-shared tbody tr td:last-child {
    white-space: nowrap;
}

.table-shared .btn {
    padding: 5px 10px;
    font-size: 0.875rem;
}

.table-shared .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.table-shared .btn-primary:hover {
    background-color: #004494;
    border-color: #004494;
}

.table-shared .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.table-shared .btn-danger:hover {
    background-color: #c82333;
    border-color: #c82333;
}

.table-shared .btn-success {
    background-color: #28a745;
    border-color: #28a745;
}

.table-shared .btn-success:hover {
    background-color: #218838;
    border-color: #218838;
}

/* Estilos para as Tags (Pendentes, Aprovados, Rejeitados) */
.card-header.bg-warning {
    background-color: #0056b3 !important; /* Azul primário para pendentes */
}

.card-header.bg-success {
    background-color: #0056b3 !important; /* Azul primário para aprovados */
}

.card-header.bg-danger {
    background-color: #0056b3 !important; /* Azul primário para rejeitados */
}

.card-header h5 {
    margin: 0;
    color: #fff !important;
    font-size: 1.2rem;
}

/* Altura das Cartões */
.card {
    height: auto;
}

.card-body {
    padding: 20px;
}

.card-header {
    padding: 15px 20px;
    font-size: 1rem;
}

/* Footer styling */
.footer {
    background-color: #333;
    color: #fff;
    padding: 25px 0;
    text-align: left;
    border-top: 2px solid #0056b3;
    margin-top: 35px;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer .footer-column {
    text-align: center; /* Centraliza o texto e conteúdo dentro da coluna */
    flex: 1;
    margin-right: 20px;
    min-width: 200px;
}

.footer .footer-column h5 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #ffffff;
}

.footer .footer-column ul {
    list-style: none;
    padding: 0;
}

.footer .footer-column ul li {
    margin-bottom: 10px;
}

.footer .footer-column ul li a {
    color: #ccc;
    text-decoration: none;
}

.footer .footer-column ul li a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.footer .social-links {
    display: flex;
    flex-direction: column; /* Mantém os ícones em coluna */
    align-items: center; /* Centraliza os ícones horizontalmente */
    margin-top: 10px;
}

.footer .social-links a {
    color: #fff;
    margin-right: 15px;
    font-size: 24px;
}

.footer .social-links a:hover {
    color: #0056b3;
}

/* Responsividade para empilhar colunas no mobile */
@media (max-width: 768px) {
    .footer .container {
        flex-direction: column;
        align-items: center;
    }
    .footer .footer-column {
        margin-bottom: 20px;
        text-align: center;
    }
    .footer .social-links {
        justify-content: center;
    }
}

/* Adicionando os estilos do CSS antigo ao novo */

/* Swiper Slide Content */
.swiper-slide-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Slide Image */
.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Swiper Slide Text */
.swiper-slide-text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.911);
}

/* Slides Container */
.slides-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Espaçamento entre os slides */
}

/* Slide Item */
.slide-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 200px; /* Largura máxima de cada slide */
    border: 1px solid #ddd; /* Bordas dos slides */
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

/* Swiper Slide */
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Custom CSS for ProtocoloView */
.container {
    max-width: 80%;
    margin: auto;
}

.section-title {
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;
}

/* Tabela Protocolo */
.tabela-protocolo {
    width: '20px';
}

/* Row Form Group */
.row .form-group {
    display: flex;
    align-items: center; /* Isso ajuda a alinhar verticalmente os elementos */
}

/* Row Form Control */
.row .form-control {
    width: 100%; /* Garante que todos os inputs ocupem o mesmo espaço */
}

/* Row Label */
.row label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

/* Datepicker Wrapper */
.datepicker-wrapper {
    display: flex;
    align-items: center;
}


/*-------------------------------- Cards Ambiental --------------------------------*/

.card-custom {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #f9f9f9;
}

.card-custom:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-custom .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0056b3;
}

.card-custom .card-text {
    font-size: 1rem;
    color: #6c757d;
}

.card-custom ul {
    text-align: left;
    padding-left: 1.5rem; /* Para alinhar os bullets */
}

.card-custom ul li {
    display: flex;
    align-items: center;
}

.card-custom ul li .mr-2 {
    margin-right: 8px;
}

.version-info {
    color: #636363;
    font-weight: 400;
}

.row .col-md-6, .row .col-lg-4 {
    display: flex;
    justify-content: center;
}