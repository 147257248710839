// Grays
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dcdcdc;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Core colors
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$white: #ffffff;
$black: #000000;
$orange: #fd7e14;

// Body
$body-bg: #f8f9fa;
$body-color: #212529;

// Links
$link-color: #007bff;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Font weights
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Alerts
$alert-dismissible-padding-r: 1rem;
$alert-bg-scale: 0.85;
$alert-border-scale: 0.9;
$alert-color-scale: 1.2;

// Button
$btn-transition: all 0.15s ease-in-out;
$btn-border-radius: 0.25rem;
$btn-link-disabled-color: $gray-600;
$box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);

// Card
$card-bg: #ffffff;
$card-spacer-x: 1.25rem;
$card-img-overlay-padding: 1rem;

// Box shadows
$box-shadow-sm: 0 .125rem .25rem rgba(0,0,0,.075);
$box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);

// Grid
$grid-gutter-width: 30px;

// Transitions
$transition-base: all .2s ease-in-out;

// Theme colors map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "orange": $orange
);

// Grays map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// Card
$card-bg: #ffffff;
$card-spacer-x: 1.25rem;
$card-img-overlay-padding: 1rem;
$card-border-radius: 0.25rem; // Adicionando a variável $card-border-radius

// Mixins
@mixin border-radius($radius) {
  border-radius: $radius;
}

// Border radius
$border-radius-sm: 0.2rem;

// Border
$border-width: 1px;
$border-color: #dee2e6;


// Font size
$font-size-base: 1rem; // 16px

// Z-index
$zindex-fixed: 1030;

// Dropdown padding
$dropdown-padding-y: 0.5rem;
$dropdown-padding-x: 1rem;

// Dropdown minimum width
$dropdown-min-width-sm: 10rem;
$dropdown-min-width-lg: 20rem;
$dropdown-min-width-xl: 30rem;

// Dropdown border radius
$dropdown-border-radius: 0.25rem;

// Input border radius
$input-border-radius: 0.25rem;

// Input border width
$input-border-width: 1px;

// Input padding
$input-padding-x: 0.75rem;

// Input color
$input-color: #495057;

// Border radius
$border-radius-lg: 0.3rem;

// Modal inner padding
$modal-inner-padding: 1rem;

// Font weights
$font-weight-medium: 500;

// Navigation pills border radius
$nav-pills-border-radius: 0.25rem;

// Navigation link padding
$nav-link-padding-x: 1rem;

// Box shadow
$box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Navbar dropdown min-width
$navbar-dropdown-min-width: 10rem;
$navbar-dropdown-min-width-lg: 15rem;
$navbar-dropdown-min-width-xl: 20rem;

// Navbar toggler padding
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;

// Box shadow large
$box-shadow-lg: 0 1rem 3rem rgba(0,0,0,.175);

// Transition base
$transition-base: all .2s ease-in-out;

// Mixin
@mixin media-breakpoint-up($name) {
  $min: map-get($grid-breakpoints, $name);

  @if $min != null {
    @media (min-width: $min) {
      @content;
    }
  }
}

// Navbar dark brand color
$navbar-dark-brand-color: #fff;

// Mixin
@mixin media-breakpoint-down($name) {
    $max: map-get($grid-breakpoints, $name);
  
    @if $max != null {
      @media (max-width: $max) {
        @content;
      }
    }
  }

  // Navbar light color
$navbar-light-color: #000;

  
// Navbar light toggler border color
$navbar-light-toggler-border-color: #ddd;

// Navbar light toggler icon background
$navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%288%2C 8%2C 8%2C 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

// Navbar link color
$nav-link-color: #007bff;

// Font sizes
$font-size-base: 1rem; // 16px
$font-size-sm: 0.875rem; // 14px

// Font family
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Font family
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-sans-serif: $font-family-base;

// Border radius
$border-radius: 0.25rem;
